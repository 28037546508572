<template>
  <div class="list">
    <el-row >
      <el-col :span="16" class="page-title">
        <span>支付管理</span>
        <span class="list-sub-title">企业可以拥有多个支付信息，默认只有一个支付被启用</span>
      </el-col>
      <el-col :span="8" class="list-opr">
        <el-input
          placeholder="请输入支付名称"
          prefix-icon="el-icon-search"
          size="small"
          @change="loadPay"
          v-model="pagination.name">
        </el-input>
        <el-button type="primary" size="small"
                   v-if="$btnCheck('order_setting_pay_create')"
                   @click="$router.push({name: 'order.setting.pay.create'})">
        <i class="el-icon-plus"></i> 新增支付</el-button>
        <el-button type="primary" size="small" @click="loadPay" v-else>搜索</el-button>
      </el-col>
    </el-row>

    <el-table
      class="list-table"
      :row-key="record => record.uuid"
      :data="payInfos"
      :loading="loading">

      <el-table-column label="支付编号" prop="uuid"></el-table-column>
      <el-table-column label="支付名称" prop="name"></el-table-column>
      <el-table-column label="已有支付" min-width="140px" align="center">
        <template slot-scope="scope">
          <div class="pay-item">
            <div v-for="(c) in (scope.row.chanelInfo||[])" :key="c.name" class="item">
              <p class="item-name"> <i class="iconfont " :class="payChanel[c.payChanel].icon" :style="{fontSize: '24px',
             color: payChanel[c.payChanel].color}"></i><span> {{payChanel[c.payChanel].name}}</span></p>
              <p class="item-label">{{c.status | applyStatus}}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="来源方式" align="center">
        <template slot-scope="scope">
          {{scope.row.belongType | filterBelongType}}
        </template>
      </el-table-column>
      <el-table-column label="启用关系" align="center">
        <template slot-scope="scope">
          {{scope.row.uuid === defaultPayUuid ? '已启用': '-'}}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.uuid !== defaultPayUuid && $btnCheck('order_setting_pay_default')"
                     @click="handleDefault(scope.row)"
                     size="small"  type="text">启用支付</el-button>
          <el-button  type="text" size="small" class="warning"
                      v-if="$btnCheck('order_setting_pay_opr')"
                      @click="$router.push({name: 'order.setting.pay.detail', params: {uuid: scope.row.uuid}})"
                      plain>编辑</el-button>
          <el-button  type="text" size="small"
                      v-if="scope.row.belongType === 1 && $btnCheck('order_setting_pay_delete')"
                      class="danger" @click="handleDelete(scope.row)" plain>删除</el-button>
        </template>
      </el-table-column>

      <empty slot="empty"></empty>

    </el-table>

    <el-pagination
      @current-change="handleNext"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total"></el-pagination>



  </div>

</template>

<script>

  import {mapGetters} from "vuex";
  export default {
    name: 'org-pay-list',
    components: {},
    computed:{
      ...mapGetters(['prove']),
    },
    mounted: function () {
      this.loadPay()
    },
    filters: {
      filterBelongType(b) {
        switch (b) {
          case 1:
            return '自建'
          case 2:
            return '授权'
        }
      }
    },
    data: function () {
      return {
        payInfos: [],
        loading: false,
        payChanel: [
          {},
          {},
          {
            name: '微信支付',
            icon:'icon-weixinzhifu',
            color: '#1AAD19'
          },
          {
            name: '支付宝',
            icon:'icon-zhifubao',
            color: '#027AFF'
          },

        ],
        payStyles: [
          {name: '全部', value: 0},
          {name: '支付宝', value: 1},
          {name: '微信', value: 2},
        ],
        defaultPayUuid: '',
        pagination: {
          name: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 1,
        },
      }
    },
    methods: {
      loadPay() {
        this.pagination.start = 0
        this.$api('mkt.pay.union', this.pagination).then(data => {
          this.payInfos = data.list || []
          this.pagination.total = parseInt(data.total)
          this.defaultPayUuid = data.default
        })
      },
      handleNext(page) {
        this.pagination.start = (page - 1) * this.pagination.limit
        this.$api('mkt.pay.list', this.pagination).then(data => {
          this.payInfos = data || []
        })
      },
      handleDelete(pay) {
        let vue = this
        this.$confirm('删除支付将导致所有相关信息全部被清除，是否继续？','提示').then(() => {
          this.$api('mkt.pay.delete',{
            uuid: pay.uuid,
          }).then(() => {
            vue.$message.success('删除成功')
           vue.loadPay()
          })
        }).catch(()=>{})
      },

      handleDefault(pay) {
        let vue = this
        this.$confirm('是否修改默认支付？','提示').then(() => {
          this.$api('mkt.pay.default',{
            uuid: pay.uuid,
            proveName: vue.prove.name,
          }).then(() => {
            vue.$message.success('修改成功')
          vue.defaultPayUuid = pay.uuid
          })
        }).catch(()=>{})
      }
    },
  }
</script>

<style lang="scss" scoped>
  .list {
    padding: $container-padding;

    .pay-item{
      display: flex;
      align-items: center;
      .item {
        flex: 1;
        margin: 0 4px;
        &-name {
          @include font-little-h()
        }

        &-label{
          @include font-little();
          color: $remark-text-color;
        }
      }
    }
    &-opr{
      display: flex;
      .el-input{
       flex: 1;
      }
      .el-button{
        margin-left: $small-space;
      }
    }


    &-sub-title {
      margin: $small-space 0;
      color: $remark-text-color;
      margin-left: $small-space;
      @include font-little();
    }

    &-table{
      margin-top: $middle-space;
    }


  }
</style>
